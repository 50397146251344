import { useQuery } from '@apollo/client'
import { Form } from '@modules'
import { RouteComponentProps, useLocation } from '@reach/router'
import {
  AccountMyGolfClubInventoryEditDetailsFormType,
  GolfClub,
  GolfEquipment,
  OptionType,
  ProductPageItemType,
  ResponseType,
  TableColumnType,
  User
} from '@types'
import { Link, navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'

import { Button, Input, StatusIcon } from '@elements'
import { golfClub as graphqlGolfClub, user as graphqlUser, golfEquipment as graphqlGolfEquipment } from '@graphql'
import { useGraphqlGolfClub, useGraphqlGolfEquipment, useWindowSize } from '@hooks'
import { AccountDetailsLayout, AccountLayout } from '@layouts'
import { addDays, formatDateTimeString } from '@utils'
import ArrowIcon from '@images/back-arrow.svg'
import '../../../account.scss'

interface AccountMyGolfClubInventoryEditDetailsProps extends RouteComponentProps<{ golfItemId: string }> {}

const EXPIRATION_DAYS = [
  { label: '0 Days', value: '0' },
  { label: '10 Days', value: '10' },
  { label: '20 Days', value: '20' },
  { label: '30 Days', value: '30' }
]

const getExpirationDays = (expiration: string, updatedAt: string): OptionType => {
  const MILLISECONDS_TO_DAYS_DIVIDER = 1000 * 60 * 60 * 24

  const expirationDate = new Date(expiration)
  const updatedAtDate = new Date(updatedAt)
  const dateDifference = +expirationDate - +updatedAtDate
  const dateDifferenceInDays = Math.round(dateDifference / MILLISECONDS_TO_DAYS_DIVIDER)
  const res = EXPIRATION_DAYS.find(option => {
    return +option.value === dateDifferenceInDays
  })

  return res || EXPIRATION_DAYS[0]
}
const AccountMyGolfClubInventoryEditDetailsForm = Form<AccountMyGolfClubInventoryEditDetailsFormType>()

const AccountMyGolfClubInventoryEditDetails: React.FC<AccountMyGolfClubInventoryEditDetailsProps> = props => {
  const { golfItemId } = props

  const { hash } = useLocation()
  const { updateGolfClub } = useGraphqlGolfClub()
  const { updateGolfEquipment } = useGraphqlGolfEquipment()

  const backUrl = '/account/store/my-golf-club-inventory'
  const isItemGolfClub = hash === '#golfClub'

  const { toRender: isMobileScreen } = useWindowSize(['mobile', 'landscape'])

  const [selectedExpirationDays, setSelectedExpirationDays] = useState<string>(EXPIRATION_DAYS[0].value)

  const [newPrice, setNewPrice] = useState<number>(0)

  const { data: userData, loading: userLoading } = useQuery<ResponseType<User>>(graphqlUser.FetchCurrentUser, {
    fetchPolicy: 'cache-only'
  })

  const { data: golfClubData, loading: golfClubLoading } = useQuery<{ res: GolfClub }>(graphqlGolfClub.FetchGolfClub, {
    variables: { id: golfItemId },
    skip: !isItemGolfClub,
    fetchPolicy: 'network-only'
  })

  const { data: golfEquipmentData, loading: golfEquipmentLoading } = useQuery<{ res: GolfEquipment }>(
    graphqlGolfEquipment.FetchGolfEquipment,
    {
      variables: { id: golfItemId },
      skip: isItemGolfClub,
      fetchPolicy: 'network-only'
    }
  )

  //USER
  const user = userData && userData.res
  const currentUserAddressCard = user?.defaultAddressCard
  const currentUserPaymentCard = user?.defaultPaymentCard

  //GOLF ITEM
  const golfClub = golfClubData && golfClubData.res
  const golfEquipment = golfEquipmentData && golfEquipmentData?.res

  const golfItem = golfClub || golfEquipment
  const golfItemLoading = golfClubLoading || golfEquipmentLoading

  useEffect(() => {
    if (expiresAt && updatedAt) {
      const defaultExpirationDays = getExpirationDays(expiresAt, updatedAt)
      setSelectedExpirationDays(defaultExpirationDays.value)
    }
  }, [])

  const isGolfClub = (golfItem: GolfClub | GolfEquipment): golfItem is GolfClub => {
    return 'golfClubModel' in golfItem
  }

  if (!userLoading && !user) navigate('/home/')
  if (!user) return null

  if (!golfItemLoading && !golfItem) navigate(backUrl)
  if (!golfItem) return null

  const { updatedAt, expiresAt, price, brand } = golfItem
  const golfItemModel = isGolfClub(golfItem) ? golfItem.golfClubModel : golfItem.golfEquipmentModel
  const golfItemType = isGolfClub(golfItem) ? golfItem.golfClubType : golfItem.golfEquipmentType
  const avatar = golfItemModel.avatar

  newPrice === 0 && setNewPrice(price)

  const columns: TableColumnType[] = [
    {
      title: 'Item',
      key: 'name',
      render(_, golfItem) {
        const title = isGolfClub(golfItem as GolfClub | GolfEquipment)
          ? golfItem.golfClubModel.title
          : golfItem.golfEquipmentModel.title

        return <>{title || '-'}</>
      }
    },
    {
      title: 'Brand',
      key: 'brand',
      render(_, golfItem) {
        return <>{golfItem?.brand?.title || '-'}</>
      }
    },
    {
      title: 'Category',
      key: 'category.name',
      render(_, golfItem) {
        return <>{golfItem?.category?.title || '-'}</>
      }
    },
    {
      title: 'price',
      key: 'price',
      render(price: number) {
        return <>{price ? '$' + price : '-'}</>
      }
    },
    {
      title: 'List Date',
      key: 'insertedAt',
      render(insertedAt: string) {
        const { formattedDate, formattedTime } = formatDateTimeString(insertedAt)
        return (
          <>
            {formattedDate}
            <br />
            {formattedTime}
          </>
        )
      }
    },
    {
      title: 'Expire Date',
      key: 'expiresAt',
      render(expired: string) {
        const { formattedDate, formattedTime } = formatDateTimeString(expired)
        return (
          <>
            {formattedDate}
            <br />
            {formattedTime}
          </>
        )
      }
    },
    {
      title: 'Status',
      key: 'status',
      render(status: string) {
        switch (status) {
          case 'ON_SALE':
            return <StatusIcon status={'For Sale'} />
          case 'SOLD':
            return <StatusIcon status={'Sold'} />
          case 'UNSOLD':
            return <StatusIcon status={'Expired'} />
          default:
            return <StatusIcon status={'Sold'} />
        }
      }
    }
  ]

  const handleUpdateGolfItem = async (form: AccountMyGolfClubInventoryEditDetailsFormType) => {
    const { price: formPrice, expiration: formExpiration } = form
    const expirationDaysNumber = +formExpiration
    const expiresAt = addDays(new Date(golfItem.expiresAt), expirationDaysNumber).toISOString().split('T')[0]

    const addressCardId = currentUserAddressCard?.id
    const paymentCardId = currentUserPaymentCard?.id

    const golfItemTypeId = golfItemType?.id

    if (golfItemId) {
      if (isItemGolfClub) {
        await updateGolfClub({
          id: golfItemId,
          userGolfItem: {
            price: formPrice,
            expiresAt,
            golfClubTypeId: golfItemTypeId,
            addressCardId,
            paymentCardId
          }
        })
      } else {
        await updateGolfEquipment({
          id: golfItemId,
          golfEquipment: {
            price: formPrice,
            expiresAt,
            golfEquipmentTypeId: golfItemTypeId,
            addressCardId,
            paymentCardId
          }
        })
      }
    }

    await navigate(`/account/store/my-golf-club-inventory/${golfItemId}/details${hash}`)
  }

  const isGolfClubPage = golfItemModel.__typename === 'GolfClubModel'
  const itemType: ProductPageItemType = isGolfClubPage ? 'GolfClubModel' : 'GolfEquipmentModel'

  return (
    <AccountLayout pageClassName={'my-golf-club-inventory-edit-details'} hideLayout={isMobileScreen}>
      {isMobileScreen ? (
        <AccountMyGolfClubInventoryEditDetailsForm onSubmit={handleUpdateGolfItem} onError={() => null}>
          {useFormMethods => {
            return (
              <div className={'account-my-offer-book-details-mobile'}>
                <div
                  className={'account-my-offer-book-details-mobile-back-button'}
                  onClick={() => navigate('/account/store/my-golf-club-inventory')}
                >
                  <ArrowIcon />
                  My Golf Item Inventory
                </div>
                <div className={'account-my-offer-book-details-mobile-heading'}>
                  <img src={avatar || ''} alt={'avatar'} />
                  <div className={'account-my-offer-book-details-mobile-heading-info'}>
                    <Link to={`/product/${golfItemModel.slug}/${itemType}`}>
                      <strong>{golfItemModel.title}</strong>
                    </Link>
                    <div className={'account-my-offer-book-details-mobile-heading-info-brand'}>{brand?.title}</div>
                  </div>
                </div>
                <div className={'account-my-offer-book-details-footer-select-item__first'}>
                  <label>
                    <em className={'uppercase'}>Price</em>
                    <div className={'account-my-offer-book-details-footer-select-item__first-hint'}>
                      *You must meet the minimum offer of $15
                    </div>
                  </label>
                  <Input
                    autoComplete="off"
                    defaultValue={price.toString()}
                    name={'price'}
                    placeholder="$0.00"
                    type="number"
                    onChange={val => setNewPrice(+val)}
                    validation={{
                      valueAsNumber: true,
                      required: { value: true, message: 'Price is required.' },
                      min: { value: 15, message: 'Minimum offer is $15' }
                    }}
                  />
                </div>
                <div className={'account-my-offer-book-details-footer-select-item'}>
                  <div className={'account-my-offer-book-details-footer-select-item__second'}>
                    <label>
                      <em>EXTEND EXPIRATION BY</em>
                    </label>
                    <AccountMyGolfClubInventoryEditDetailsForm.Select
                      name={'expiration'}
                      clearable={false}
                      options={EXPIRATION_DAYS}
                      defaultValue={selectedExpirationDays}
                      onChange={val => setSelectedExpirationDays(val ?? '0')}
                      rounded
                    />
                  </div>
                </div>
                <div className={'account-my-offer-book-details-footer-button-group'}>
                  <Button
                    size={'lg'}
                    type={'roundBlack'}
                    onClick={() => navigate(`/account/store/my-golf-club-inventory/${golfItemId}/details#${hash}`)}
                  >
                    Cancel
                  </Button>
                  <Button
                    size={'lg'}
                    disabled={newPrice < 15 || (newPrice === golfItem.price && selectedExpirationDays === '0')}
                    type={'roundWithHoverBg'}
                    submit
                  >
                    Save
                  </Button>
                </div>
              </div>
            )
          }}
        </AccountMyGolfClubInventoryEditDetailsForm>
      ) : (
        <AccountMyGolfClubInventoryEditDetailsForm onSubmit={handleUpdateGolfItem} onError={() => null}>
          {useFormMethods => {
            const { getValues } = useFormMethods
            return (
              <AccountDetailsLayout
                title={'My Golf Club Inventory'}
                details={{
                  title: 'Item',
                  text: golfItemModel.title,
                  link: `/product/${golfItemModel.slug}/${itemType}`,
                  date: updatedAt,
                  expired: expiresAt
                }}
                backUrl={backUrl}
                avatar={avatar}
                columns={columns}
                data={golfItem}
                loading={userLoading || golfItemLoading}
              >
                <div className={'account-my-offer-book-details-footer-select-item__first-hint'}>
                  *You must meet the minimum offer of $15
                </div>
                <div className={'account-my-offer-book-details-footer-select'}>
                  <div className={'account-my-offer-book-details-footer-select-item'}>
                    <div className={'account-my-offer-book-details-footer-select-item__first'}>
                      <label>
                        <em className={'uppercase'}>price</em>
                      </label>
                      <Input
                        autoComplete="off"
                        defaultValue={price.toString()}
                        name={'price'}
                        placeholder="$0.00"
                        type="number"
                        onChange={val => setNewPrice(+val)}
                        validation={{
                          valueAsNumber: true,
                          required: { value: true, message: 'Price is required.' },
                          min: { value: 15, message: 'Minimum offer is $15' }
                        }}
                      />
                    </div>
                  </div>
                  <div className={'account-my-offer-book-details-footer-select-item'}>
                    <div className={'account-my-offer-book-details-footer-select-item__second'}>
                      <label>
                        <em>EXTEND EXPIRATION BY</em>
                      </label>
                      <AccountMyGolfClubInventoryEditDetailsForm.Select
                        name={'expiration'}
                        clearable={false}
                        options={EXPIRATION_DAYS}
                        defaultValue={selectedExpirationDays}
                        onChange={val => setSelectedExpirationDays(val ?? '0')}
                        rounded
                      />
                    </div>
                  </div>
                </div>
                <div className={'account-my-offer-book-details-footer-buttons'}>
                  <Button
                    type={'roundBlack'}
                    size={'lg'}
                    onClick={() => navigate(`/account/store/my-golf-club-inventory/${golfItemId}/details#${hash}`)}
                  >
                    Cancel
                  </Button>
                  <Button
                    size={'lg'}
                    disabled={newPrice < 15 || (newPrice === golfItem.price && selectedExpirationDays === '0')}
                    type={'roundWithHoverBg'}
                    submit
                  >
                    Save
                  </Button>
                </div>
              </AccountDetailsLayout>
            )
          }}
        </AccountMyGolfClubInventoryEditDetailsForm>
      )}
    </AccountLayout>
  )
}

export default AccountMyGolfClubInventoryEditDetails
